export default {
  page_name: "myas : 100k benefit-choice",
  channel: "myas",

  tags: {
    mpchoice_landing: {
      load: {
        page_name: "myas : 100k benefit-choice",
        channel: "myas",
        events: "event87",
      },
    },
    mpchoice_mvp_nomination_form: {
      load: {
        page_name: "myas : 100k benefit-nominate-status",
        channel: "myas",
        events: "",
      },
    },
    mpchoice_confirmation: {
      load: {
        page_name: "myas : 100k benefit-confirmation",
        channel: "myas",
        events: "event88",
      },
    },
    mpchoice_error: {
      load: {
        page_name: "myas : 100k benefit-choice",
        channel: "myas",
        events: "event5",
      },
    }, 
  },
};
