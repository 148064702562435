import "./index.scss";
import confirmationHeaderBackGround from "../../assets/avis_navy_rectangle.png";
import carImage from "../../assets/jeep_avis.png";
import UserAccountContext from "../../context/UserAccountContext";
import { AccountStateType } from "../../state/InitialState";
import { useContext, useMemo } from "react";
import { getMemberFirstName, getABGTierName} from "../../helpers/getMemberData";
import ABGStrings from "../../helpers/tagging/ABG-strings";
import { addTaggingUtil, createUtagObject, waitForUtagAndLink, waitForUtagAndTrack } from "../../helpers/tagging/tagging-utils";
import useProductPageTitle from "../../hooks/useProductPageTitle";
import { AvisProductPageTitle, BasePageTitle } from "../../helpers/constants";

const ABGConfirmationPage = () => {
  useProductPageTitle(`${AvisProductPageTitle}${BasePageTitle}`);

  window.scroll({ 
    top: 0, 
    left: 0, 
    behavior: 'auto' 
  });

  const { accountState }: { accountState: AccountStateType } = useContext(UserAccountContext);

  useMemo(() => {
    let { page_name, channel, events } = ABGStrings.tags.abg_status_match_confirmation.load;
    waitForUtagAndTrack(createUtagObject(page_name, channel, events));
    addTaggingUtil();
  }, []);

  const carRentalOnClick = () => {
    waitForUtagAndLink(ABGStrings.tags.abg_status_match_link.link_tracking);
  }

  return(
    <div className="ABGConfirmation">
      <section className="ABGConfirmation_header">
        <auro-background 
            bg={`url(${confirmationHeaderBackGround}) center center/cover no-repeat`}
          >
          <div className="header_container">
            <div className="header_text">
                <auro-header>Thank you, {getMemberFirstName(accountState)}!</auro-header>
            </div>
            <div className="header_image">
              <img src={carImage} alt="Avis car logo"></img>
            </div>
          </div>
        </auro-background>
      </section>
      <section className="ABGConfirmation_body">
        <div>
          <div className="header_text_mobile">
            <auro-header>Thank you, {getMemberFirstName(accountState)}!</auro-header>
          </div>
          <auro-header level="3" display="600">
            Your request for Avis {getABGTierName(accountState)}<sup>®</sup> is approved.
          </auro-header>
          <p>Your status is now active in your Avis profile. You're all set. </p>
          <p>Have an upcoming trip? <auro-hyperlink href="https://www.alaskaair.com/car-rental/?clientId=591110#/searchcars" onClick={() => carRentalOnClick()} external target>
                Book your Avis car 
            </auro-hyperlink> and enjoy your new status benefits.
          </p>
        </div>
      </section>
    </div>
  );
};

export default ABGConfirmationPage;