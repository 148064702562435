const toggleInterruption = (elSelector: string) => {
    let interruption = document.querySelector(elSelector)!;
  
    if (!interruption) return;
  
    if (interruption.hasAttribute("open")) {
      interruption.removeAttribute("open");
    } else {
      interruption.removeAttribute("open");
      interruption.setAttribute("open", "true");
    }
  };

  export default toggleInterruption;