import axios, { AxiosResponse } from "axios";
import { appInsights } from "./AppInsights";
import {
  IEnrollPerksRequest,
  IGetPerkSelectionTokenRequest,
  IGetPerkSelectionTokenResponse,
  IGetPerksRequest,
  IPerksResponse,
} from "../types/perks";

const baseURL = `${process.env.REACT_APP_APIM_BASE_URL}/1/Retain/MileagePlan/mpchoice/api`;

const getPerks = async (payload: IGetPerksRequest, token: string): Promise<AxiosResponse<IPerksResponse> | null> => {
  const getPerksURL = `${baseURL}/GetPerks`;

  // Environment validation
  if (!process.env.REACT_APP_MPCHOICE_BFF_KEY || !process.env.REACT_APP_APIM_BASE_URL) {
    const errorMessage = "Required environment variables are missing";
    appInsights.trackException({ exception: new Error(errorMessage) });
    throw new Error(errorMessage);
  }

  try {
    var mpNumber = payload.mileagePlanNumber;
    var guid = payload.guid;
    var asoaMiles = payload.asoaMiles;
    appInsights.trackEvent({
      name: `[Perks Service] Get Perks called`,
      properties: { mpNumber, guid, asoaMiles },
    });

    const response = await axios.post(
      getPerksURL,
      { ...payload },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Ocp-Apim-Subscription-Key": process.env.REACT_APP_MPCHOICE_BFF_KEY,
        },
      }
    );

    appInsights.trackEvent({
      name: "[Perks Service] Get Perks success",
      properties: { status: response.status },
    });

    return response;
  } catch (error: any) {
    appInsights.trackException({ exception: error });

    if (axios.isAxiosError(error)) {
      // Handle Axios-specific errors, e.g., network or API issues
      return error.response || null;
    } else {
      // Handle non-Axios errors
      throw new Error("An unexpected error occurred");
    }
  }
};

const enrollPerks = async (
  payload: IEnrollPerksRequest,
  token: string,
  perkSelectionToken: string
): Promise<AxiosResponse<IPerksResponse> | null> => {
  const enrollPerksURL = `${baseURL}/EnrollMPPerks`;

  // Environment validation
  if (!process.env.REACT_APP_MPCHOICE_BFF_KEY || !process.env.REACT_APP_APIM_BASE_URL) {
    const errorMessage = "Required environment variables are missing";
    appInsights.trackException({ exception: new Error(errorMessage) });
    throw new Error(errorMessage);
  }

  try {
    appInsights.trackEvent({
      name: `[Perks Service] Enroll perks called`,
    });

    const response = await axios.post(
      enrollPerksURL,
      { ...payload },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Ocp-Apim-Subscription-Key": process.env.REACT_APP_MPCHOICE_BFF_KEY,
          "Idempotency-Key": perkSelectionToken,
        },
      }
    );

    appInsights.trackEvent({
      name: "[Perks Service] Enroll perks success",
      properties: { status: response.status },
    });

    return response;
  } catch (error: any) {
    appInsights.trackException({ exception: error });

    if (axios.isAxiosError(error)) {
      // Handle Axios-specific errors, e.g., network or API issues
      return error.response || null;
    } else {
      // Handle non-Axios errors
      throw new Error("An unexpected error occurred");
    }
  }
};

const getPerkSelectionTokens = async (
  payload: IGetPerkSelectionTokenRequest,
  token: string
): Promise<AxiosResponse<IGetPerkSelectionTokenResponse> | null> => {
  const getPerkSelectionTokenURL = `${baseURL}/EnrollMPPerks/tokens`;

  // Environment validation
  if (!process.env.REACT_APP_MPCHOICE_BFF_KEY || !process.env.REACT_APP_APIM_BASE_URL) {
    const errorMessage = "Required environment variables are missing";
    appInsights.trackException({ exception: new Error(errorMessage) });
    throw new Error(errorMessage);
  }

  try {
    appInsights.trackEvent({
      name: `[Perks Service] Get Perk Selection Token called`,
    });

    const response = await axios.post(
      getPerkSelectionTokenURL,
      { ...payload },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Ocp-Apim-Subscription-Key": process.env.REACT_APP_MPCHOICE_BFF_KEY,
        },
      }
    );

    appInsights.trackEvent({
      name: "[Perks Service] Get Perk Selection Token success",
      properties: { status: response.status },
    });

    return response;
  } catch (error: any) {
    appInsights.trackException({ exception: error });

    if (axios.isAxiosError(error)) {
      // Handle Axios-specific errors, e.g., network or API issues
      return error.response || null;
    } else {
      // Handle non-Axios errors
      throw new Error("An unexpected error occurred");
    }
  }
};

export { getPerks, enrollPerks, getPerkSelectionTokens };
