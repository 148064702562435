const ChoiceCardContent = [
  {
    id: 1,
    hText: "Alaska Lounge+ membership",
    cHead:
        "Our spacious lounges are the perfect place to relax and recharge during your travels. Membership includes access for yourself and up to two guests or your immediate family when traveling together using any airline boarding pass.",
    cText: [
      "With an Alaska Lounge+ membership, enjoy all the perks, like local sips and a snack, every time you fly.",
      "Alaska Lounge+ members get access to 90+ partner lounges, in addition to our nine Alaska Lounge locations. There are no refunds for existing Lounge or Lounge+ memberships.",
    ],
    cIconCategory: "terminal",
    cIconName: "lounge",
    supID: 3,
    BFFid: 1,
    confirmationAlert: "You have chosen an Alaska Lounge+ membership",
    confirmationText:
        "Our spacious lounges are the perfect place to relax and recharge during your travels.   Membership includes access for yourself and up to two guests or immediate family when traveling together using any airline boarding pass. \n\n With an Alaska Lounge+ membership, enjoy all the perks, like local sips and a snack, every time you fly. \n\n Alaska Lounge+ members get access to 90+ oneworld® partner lounges, in addition to our nine Alaska Lounge locations. There are no refunds for existing Lounge or Lounge+ memberships.",
    confirmationButton: "NA",
    confirmationDetails: "NA",
  },
  {
    id: 2,
    hText: "Complimentary Wi-fi for a year",
    cHead:
        "Valid for complimentary Wi-Fi access whenever you fly on Alaska Airlines-marketing flights through the end of 2025.",
    cText: [
      "You'll receive an email within 72 hours from Wi-Fi Onboard (formerly Gogo Inflight), to complete the Wi-Fi account setup process - or to activate your complimentary account, if you have already created one.",
      "No refunds will be given for existing monthly or annual Wi-Fi pass members who choose this option for any time period that overlaps between paid membership and this selection.",
    ],
    cIconCategory: "in-flight",
    cIconName: "wifi",
    supID: 4,
    BFFid: 2,
    confirmationAlert: "You have chosen Complimentary Wi-fi for a year",
    confirmationText:
        "Valid for complimentary Wi-Fi access on Alaska-marketed flights (operated by Alaska, Horizon Air, or SkyWest airlines) for this benefit year. \n\n You'll receive an email from Wi-Fi Onboard (formerly Gogo Inflight), to complete the Wi-Fi account setup process - or to activate your complimentary account, if you have already created one. \n\n No refunds will be given for existing monthly or annual Wi-Fi pass members who choose this option for any time period that overlaps between paid membership and this selection.",
    confirmationButton: "NA",
    confirmationDetails: "NA",
  },
    {
    id: 3,
    hText: "50,000 Bonus miles",
    cHead:
      "This benefit immediately deposits 50,000 bonus miles into your Mileage Plan account.",
    cText: [
      "Bonus miles go towards your net total miles you can use for award travel, discounts, and other awards. These bonus miles are not elite-qualifying miles and do not count towards a change in your Mileage Plan tier.",
    ],
    cIconCategory: "terminal",
    cIconName: "plane-diag-stroke",
    supID: 2,
    BFFid: 3,
    confirmationAlert:
      "You have chosen 50,000 Bonus miles for your Mileage Plan™.",
    confirmationText:
      "Bonus miles go towards your net total miles you can use for award travel, discounts, and other awards. These bonus miles are not elite-qualifying miles and do not count towards a change in your Mileage Plan tier.",
    confirmationButton: "NA", // placeholder not currently used
    confirmationDetails: "NA", // placeholder not currently used
  },
  {
    id: 4,
    hText: "Gift a MVP Gold status",
    cHead:
      "Select someone you know to be MVP Gold. Gifted status will be effective within 2 weeks after selection.",
    cText: [
      "If you already nominated someone for MVP, you can boost them now to MVP Gold. If you have not nominated anyone before, choose a Mileage Plan member to be your recipient. Recipient status for MVP Gold will be valid through December 31, 2025.",
    ],
    cIconCategory: "in-flight",
    cIconName: "elite",
    supID: 5,
    BFFid: 4,
    confirmationAlert:
      "You have chosen to nominate nominated-user for MVP Gold status.", // regex replace: nominated-user
    confirmationText:
      "Thank you for selecting someone to receive MVP Gold status. Gifted status will be effective within approximately 2 weeks after your benefit selection. \n\n Recipient status for MVP Gold (for those qualifying for 100K in 2024 calendar year) will be valid January 2025 through December 31, 2025.",
    confirmationButton: "NA",
    confirmationDetails: "NA",
  },
  {
    id: 5,
    hText: "Complimentary CLEAR® Plus membership",
    cHead:
      "The CLEAR Plus membership brings you a faster way to verify your identity at the airport security checkpoint.",
    cText: [
      "Fly through security at 50+ airports nationwide with CLEAR Plus using just your eyes or ﬁngerprints. Just step up to a CLEAR pod to conﬁrm you are you with your ﬁngerprint or eyes instead of waiting in line to show your ID. CLEAR Plus members also enjoy faster entry at stadiums and entertainment venues across the country.",
    ],
    cIconCategory: "partnership",
    cIconName: "clear",
    supID: 6,
    BFFid: 5,
    confirmationAlert: "You have chosen to receive a complimentary CLEAR® Plus membership.",
    confirmationText: "Thank you for selecting a complimentary CLEAR® Plus membership. You will receive instructions via email on how to register for your benefit in 1-4 weeks. ",
    confirmationButton: "NA",
    confirmationDetails: "NA",
  },
];

export default ChoiceCardContent;
