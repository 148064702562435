import "./styles.scss";
import { PerksScreens } from "../../../helpers/constants";
import ContentViewer from "../../ContentViewer";
import Nav from "../../MPPerksNavigation";
import { useEffect, useState } from "react";
import { replacePlaceholderName } from "../../../helpers/replacePlaceholderName";

type RedemptionInstructionData = {
  title: string;
  href: string;
  order: number;
  redeemInstructionRichText: string;
  redeemInstructionCtaHandling: string;
};

//create an interface called ITermsAndConditionViewerProps
interface ITermsAndConditionViewerProps {
  title?: string;
  data?: RedemptionInstructionData;
  placeholderNameObj?: object;
}

const TermsAndConditionViewer = (props: ITermsAndConditionViewerProps) => {
  const { title, data, placeholderNameObj } = props;
  const { redeemInstructionRichText, redeemInstructionCtaHandling } = data ?? {};
  const [termsAndCondition, setTermsAndCondition] = useState<any>(null);

  useEffect(() => {
    if (redeemInstructionRichText && redeemInstructionCtaHandling === "onClick") {
      setTermsAndCondition({
        memo: replacePlaceholderName(redeemInstructionRichText, {
          ...placeholderNameObj,
        }),
      });
    }
  }, [redeemInstructionRichText]);

  return (
    <div className="content-viewer">
      <Nav
        title={title ?? "Terms and Conditions"} // use the title of use the default "Terms and Conditions"
        returnToScreen={PerksScreens.Hub}
        customBackgroundColor="var(--Green-green-background-25, #FBFFF6)"
      />
      <ContentViewer content={termsAndCondition?.memo} />
    </div>
  );
};

export default TermsAndConditionViewer;
