import { useContext } from "react";
import {
  AccountStateType,
  ContentStateType,
} from "../../../state/InitialState";
import { ContentContext } from "../../../context/ContentContext";
import { replacePlaceholderName } from "../../../helpers/replacePlaceholderName";
import UserAccountContext from "../../../context/UserAccountContext";
import { PerksContext } from "../../../context/PerksContext";
import "./index.scss";
import { formatNumber } from "./../../../helpers/formatNumber";
import MarkdownRenderer from "../../MarkdownRenderer";

const MPNextPerksMilestone = () => {
  const { contentState }: { contentState: ContentStateType } =
    useContext(ContentContext);
  const { accountState }: { accountState: AccountStateType } =
    useContext(UserAccountContext);
  const {
    perksState: { perks },
  } = useContext(PerksContext);

  const { upcomingMilestones } = perks;

  const { remainingMilestoneDetails } = contentState?.Content[3]?.perksLanding
    ?.milestoneSummary?.upcomingMilestoneDetails || {
    remainingMilesDetails:
      "Only **{remaining-miles} more to go** until your {next-milestone-required} milestone.",
  };

  const remainingMiles = (
    (Number(upcomingMilestones.nextMilestoneMile) || 0) -
    (Number(accountState?.MileagePlanDetails?.asoaMiles) || 0)
  ).toString();

  return (
    <>
      {upcomingMilestones.nextMilestone && (
        <div className="remaining-miles-text">
          <MarkdownRenderer
            markdown={replacePlaceholderName(remainingMilestoneDetails || "", {
              "remaining-miles": formatNumber(remainingMiles) || "",
              "next-milestone-required": upcomingMilestones.nextMilestone,
            })}
          />
        </div>
      )}
    </>
  );
};

export default MPNextPerksMilestone;
