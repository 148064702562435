import React, { useContext, useState } from "react";
import "./index.scss";
import PerksBenefitItem, { PerkBenefitItemProps } from "./PerksBenefitItem";
import MilestoneBadge, { MilestoneBadgeType } from "./MilestoneBadge";
import { formatNumber } from "../../../../helpers/formatNumber";
import { ContentStateType } from "../../../../state/InitialState";
import { ContentContext } from "../../../../context/ContentContext";
import { replacePlaceholderName } from "./../../../../helpers/replacePlaceholderName";
import ContentViewer from "../../../ContentViewer";
import cxs from "cxs";

export interface ChipDetailProps {
  textColor: string;
  backgroundColor: string;
  subtractColor: string;
  title: string;
}

export interface ExpandingButtonProps {
  expandedText: string;
  unexpandedText: string;
  textColor: string;
}

export interface FuturePerksDetailProps {
  info: string;
  perkBenefitsLst: PerkBenefitItemProps[];
  chip: ChipDetailProps | undefined;
  summary?: string;
  mileStoneStatus?: string;
  oneworldTierstatus?: string;
  qualifyingMiles?: number | string;
  isCurrentMilestone?: boolean;
  perksLadderText?: string;
  perksLadderTextShowIf?: boolean;
  milestoneHeading?: string;
  expandingButton?: ExpandingButtonProps | undefined;
}

const MPPerksDetailsList: React.FC<FuturePerksDetailProps> = (props) => {
  const { contentState }: { contentState: ContentStateType } = useContext(ContentContext);
  const { eliteQualifyingMiles } =
    contentState?.Content[3]?.perksLanding?.milestoneSummary?.currentMilestoneDetails || {};
  const [isExpanded, setIsExpanded] = useState(false);
  const filterPerkBenefitLst = props.perkBenefitsLst?.filter((item) => item.name !== "");
  const itemsToShow = isExpanded ? filterPerkBenefitLst : filterPerkBenefitLst?.slice(0, 3);
  const isShowMoreEnable = props.perkBenefitsLst?.filter((item) => item.name !== "").length > 3;

  const handleToggleShowMore = () => {
    setIsExpanded(!isExpanded);
  };

  const styles = {
    expandingButton: cxs({
      color: `var(--${props.expandingButton?.textColor}, #03314F)`,
    }),
  };

  return (
    <div className={!props.qualifyingMiles ? "future-perks-container" : ""}>
      <div className="milestone-badge-container">
        <MilestoneBadge
          textColor={props.chip?.textColor ?? ""}
          backgroundColor={props.chip?.backgroundColor ?? ""}
          offsetColor={props.chip?.subtractColor ?? ""}
          title={props.chip?.title ?? ""}
          type={props.chip?.subtractColor === "" ? MilestoneBadgeType.Normal : MilestoneBadgeType.Colored}
        />
        <p className="milestone-status" style={{ visibility: props.perksLadderTextShowIf ? "visible" : "hidden" }}>
          {props?.perksLadderText}
        </p>
      </div>
      {props.mileStoneStatus && props.oneworldTierstatus ? (
        <>
          <div className="current-status-text-container">
            <span className="current-status-tier">{props.mileStoneStatus} / oneworld&reg; </span>
            <span className={`one-world-${props.oneworldTierstatus}`}>{props.oneworldTierstatus}</span>
          </div>
        </>
      ) : (
        props.milestoneHeading && (
          <div className="current-status-text-container">
            <span className="current-status-tier">{props.milestoneHeading}</span>
          </div>
        )
      )}

      {props.qualifyingMiles !== undefined && (
        <div className="elite-qualifying-miles">
          {replacePlaceholderName(eliteQualifyingMiles || "", {
            qm:
              props.qualifyingMiles === 0
                ? `Less than ${formatNumber(10000)}`
                : (formatNumber(props.qualifyingMiles) as string),
          })}
        </div>
      )}

      <p className={props.isCurrentMilestone ? "current-milestone-description" : "perk-description"}>{props.info}</p>
      <div className="benefit-list">
        {itemsToShow?.map((benefit: PerkBenefitItemProps, index: number) => (
          <PerksBenefitItem key={index} {...benefit} />
        ))}

        <ContentViewer content={props.summary || ""} isPage={false} />

        {isShowMoreEnable && (
          <div className={`show-more ${styles.expandingButton}`} onClick={handleToggleShowMore}>
            {isExpanded && (
              <span>
                <auro-icon test-id="show-less" category="interface" name="chevron-up" />
                {props.expandingButton?.expandedText}
              </span>
            )}
            {!isExpanded && (
              <span>
                <auro-icon test-id="show-more" category="interface" name="chevron-down" />
                {props.expandingButton?.unexpandedText}
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MPPerksDetailsList;
