/**
 * Checks if the provided value is numeric.
 *
 * This function determines if the input value is a valid number.
 * It accepts both numbers and strings as input. For strings, it
 * attempts to convert them to a number and checks if the result
 * is not NaN. For numbers, it checks that the value is of type
 * 'number' and is not NaN.
 *
 * @param {string | number} value - The value to be checked.
 * @returns {boolean} - Returns true if the value is numeric,
 *                      false otherwise.
 */
export const isNumeric = (value: string | number) => {
  return (typeof value === "number" && !isNaN(value)) || !isNaN(Number(value));
};
