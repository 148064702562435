import "./index.scss";
import { useContext } from "react";
import cxs from "cxs";
import MPPerkIcon from "../MPPerkIcon";
import { formatNumber } from "../../../../helpers/formatNumber";
import { getIcon } from "../../../../helpers/getIcon";
import { ContentStateType } from "../../../../state/InitialState";
import { ContentContext } from "../../../../context/ContentContext";
import { PerksContext } from "../../../../context/PerksContext";
import { isNumeric } from "../../../../helpers/isNumeric";
import { isStringContainsDate } from "../../../../helpers/isStringContainsDate";
import { formatDate } from "../../../../helpers/formatDate";
import { PerksScreens } from "../../../../helpers/constants";
import { extractKeysAndCreateObject } from "../../../../helpers/extractKeysAndCreateObj";
import { replacePlaceholderName } from "../../../../helpers/replacePlaceholderName";

export interface MPPerkCardProps {
  perkBenefitId: number;
  selectionDate?: string;
  indx: number;
}

interface Field {
  title: string;
  value: string;
  order: number | string;
}

interface LinkType {
  title: string;
  href: string;
  order: number | string;
  redeemInstructionRichText?: string;
  redeemInstructionCtaHandling?: string;
}

export default function MPPerkCard(perk: Readonly<MPPerkCardProps>) {
  const {
    perksState: { perks },
    perksDispatch,
  } = useContext(PerksContext);
  const { contentState }: { contentState: ContentStateType } = useContext(ContentContext);

  const { cardBackgroundColor, perkTitleColor, fieldValueColor, linkColor, fieldTitleColor } =
    contentState?.Content[3]?.perksLanding?.pastPerks || {};

  const { list, default: defaultIconName, defaultCategory } = contentState?.Content[2]?.icons || {};

  const styles = {
    cardContainer: cxs({
      background: `var(--${cardBackgroundColor}, #fff)`,
    }),
    perkTitle: cxs({
      color: `var(--${perkTitleColor}, #2E2E2E)`,
    }),
    fieldValue: cxs({
      color: `var(--${fieldValueColor}, #151515)`,
    }),
    fieldTitle: cxs({
      color: `var(--${fieldTitleColor}, #151515)`,
    }),
    link: cxs({
      color: `var(--${linkColor}, #03314F)`,
    }),
  };

  const getPerk = (perkId: number) => {
    const perk = contentState?.Content[4]?.perks?.find((perk) => perk.id === perkId);

    if (perk) {
      return perk;
    }
  };

  const toTitleCase = (str: string) => {
    return str
      ?.toLowerCase()
      .split(" ")
      .map((title: string) => title.charAt(0).toUpperCase() + title.slice(1))
      .join(" ");
  };

  const getFormattedValue = (value: string, selected: Field): string => {
    if (isStringContainsDate(selected.value)) {
      return formatDate(value) ?? "";
    }

    if (isNumeric(value)) {
      return formatNumber(value) ?? "";
    }

    return value ?? "";
  };

  const perksContent = getPerk(perk.perkBenefitId);

  const handleTermsAndConditionsClick = (
    data: any,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    resultObject: Record<string, unknown>
  ) => {
    event.preventDefault();
    // call dispatch to set active screen, passing a custom title to the screen, and also can pass a custom data that can be access from the props of the screen
    perksDispatch({
      type: "SET_ACTIVE_SCREEN",
      value: {
        ...{ screenName: PerksScreens.TermsAndCondition, title: data?.title },
        ...{ data },
        ...{ placeholderNameObj: resultObject },
      },
    });
  };

  const links: LinkType[] | undefined = perksContent?.pastPerksCardProps?.links;
  const fields: Field[] | undefined = perksContent?.pastPerksCardProps?.fields;

  return (
    <div className={`perk-card ${styles.cardContainer}`} data-testid={`past-perk-card-${perk.indx}`} key={perk.indx}>
      <MPPerkIcon
        id={perk.perkBenefitId}
        type={getIcon(perk.perkBenefitId, list || [], defaultCategory, defaultIconName).name}
        category={getIcon(perk.perkBenefitId, list || [], defaultCategory, defaultIconName).category}
      />
      <div className="perk-card-data">
        <div className={`perk-card-title ${styles.perkTitle}`}>
          {`${toTitleCase(perksContent?.perkSummary as string)}`}
        </div>

        {fields?.length ? (
          <div>
            {fields
              .sort((a, b) => Number(a.order) - Number(b.order))
              .map((selected, index) => {
                const value = perks.pastPerksList[perk.indx][selected.value] as string;
                const formattedValue = getFormattedValue(value, selected);

                return (
                  <div className="field-container" key={index}>
                    <div className={`${styles.fieldTitle}`}>{selected.title}</div>
                    <div className={`${styles.fieldValue}`}>{formattedValue}</div>
                  </div>
                );
              })}
          </div>
        ) : null}

        {links?.length ? (
          <div className="perk-card-link-container">
            {links
              .sort((a, b) => Number(a.order) - Number(b.order))
              .map((link, index) => {
                return link.redeemInstructionCtaHandling === "onClick" ? (
                  <a
                    className={`perk-card-link ${styles.link}`}
                    key={index}
                    rel="noreferrer"
                    onClick={(event) =>
                      handleTermsAndConditionsClick(
                        link,
                        event,
                        extractKeysAndCreateObject(
                          link.redeemInstructionRichText as string,
                          perks.pastPerksList[perk.indx]
                        )
                      )
                    }
                  >
                    {link.title}
                  </a>
                ) : (
                  // eslint-disable-next-line react/jsx-no-target-blank
                  <a
                    className={`perk-card-link ${styles.link}`}
                    key={index}
                    target={
                      perksContent?.perkName?.includes("LOUNGE_DAY") &&
                      window.navigator.userAgent.toUpperCase().includes("ALKAPP/IOS")
                        ? "_self"
                        : link.redeemInstructionCtaHandling ?? "_blank"
                    }
                    href={replacePlaceholderName(
                      link.href,
                      extractKeysAndCreateObject(link.href, perks.pastPerksList[perk.indx])
                    )}
                  >
                    {link.title}
                  </a>
                );
              })}
          </div>
        ) : null}
      </div>
    </div>
  );
}
