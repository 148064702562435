export const formatDate = (dateString: string): string | undefined => {
  if (!dateString) return;
  try {
    // Parse the date string
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return undefined;
    }

    // Format the date in (MM/DD/YYYY)
    const options: Intl.DateTimeFormatOptions = {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  } catch {
    return undefined;
  }
};
