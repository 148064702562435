import { debounce } from "throttle-debounce";
/**
 * Creates a product tag for each lounge membership type
 * that is passed into the utag.view() event for the Confirmation view
 * after a successful purchase
 *
 * Products Format:
 * products="[category];[productType:productDetails];[quantity];[totalPrice];"
 * totalPrice should equal what ever the membership fee is based on tier status
 * example: “;Ancillary_BR:Lounge;1;450.00;"
 *
 * @param {object} membershipOption - lounge membership option
 */
export const createProductTag = (membershipOption) => {
  const productDetails = membershipOption.displayName.replace(/\s+/g, ""); // remove spaces with regex
  const totalPrice = membershipOption.price.toFixed(2); // add two decimal places for cents
  return `;Ancillary_BR:${productDetails};1;${totalPrice}`;
};

/**
 * This function is used to fire a tagging event onClick
 *
 * These are debounced with 100ms threshhold to prevent duplicate tagging events
 * being fired by unintentional re-renders in React
 *
 * @param {string} linkId - id assigned for the event or link, e.g. "Alaska Lounge membership: billing-saved email"
 * */

export const fireTagEvent = (linkId) => debounceWaitForUtag(() => {
  const data = {
    tealium_event: "link_click",
    link_tracking: linkId,
  };
  window.utag.link(data);
});

/**
 *
 */
export const addTaggingUtil = () => {
  let temp_page_name = "myas: 100k benefit-choice";
  if(window.location.pathname === "/account/avis-status-match" || window.location.pathname === "/account/avis-status-match/confirmation"){
    temp_page_name = "myas : avis status match";
  }
  window.utag_data = {
    page_name: temp_page_name,
    page_type: "myas",
    channel: "myas"
  };
  const scriptUrl = `//tags.tiqcdn.com/utag/alaska/main/${process.env.REACT_APP_RELEASE_TAGGING_ENV}/utag.js`;
  const scriptElement = document.createElement('script');
  scriptElement.src = scriptUrl;
  scriptElement.type = 'text/javascript';
  scriptElement.async = true;
  const firstFoundScriptElement = document.getElementsByTagName('script')[0];
  if(firstFoundScriptElement){firstFoundScriptElement.parentNode.insertBefore(scriptElement, firstFoundScriptElement)}
}

/**
 * debounceWaitForUtag ensure that functionality that inherits form this debounces the same way.
 * cb
 */
export const debounceWaitForUtag = debounce(100, (cb) => { waitForUtag(cb) });

/**
 * wait for utag waits until utag has been loaded onto the dom before triggering the callback's functionality
 *
 * @param {function} cb the callback to be triggered after utag is loaded onto the dom.
 */
export const waitForUtag = (cb) => {
  if (window.utag) {
    cb();
  } else {
    setTimeout(waitForUtag, 1000, cb);
  }
};

/**
 *
 * @param {string} link_tracking
 */
export const waitForUtagAndLink = (link_tracking) => {
  waitForUtag(() => {
    const taggingObj = {
      link_tracking: link_tracking
    };
    window.utag.link(taggingObj);
  });
}

/**
 * Debounced tealium event that triggers utag.view
 *
 * @param {*} data
 */
export const waitForUtagAndTrack = (data) => {
  debounceWaitForUtag(() => {
    window.utag.view(data);
  });
};

export const createUtagObject = (pageName, channel, events) => {
  return {
    page_name: pageName,
    channel: channel,
    events: events,
    page_type: channel,
  };
};