import "./index.scss";

export type WindowIconProps = {
  category: string;
  icon: string;
  iconContainerClassName?: string;
  variant: "solid" | "twoTone" | "outline";
  size?: "large";
  testId?: string;
};

const WindowIcon = (props: WindowIconProps) => {
  const { category, icon, variant, iconContainerClassName, size, testId } = props;

  return (
    <div className={`windowIcon__container ${variant} ${size}`} data-testid={testId}>
      <div className={`windowIcon___foreground ${iconContainerClassName}`}>
        <auro-icon category={category} name={icon} customColor customSize />
      </div>
      <div className="windowIcon__background" />
    </div>
  );
};

export default WindowIcon;
