import "./style.scss";
import { useContext } from "react";
import { PerksScreens } from "../../helpers/constants";
import { ACTIONS, PerksContext } from "../../context/PerksContext";
import { ContentStateType } from "../../state/InitialState";
import { ContentContext } from "../../context/ContentContext";
import { extractKeysAndCreateObject } from "../../helpers/extractKeysAndCreateObj";
import cxs from "cxs";
import { IPerkBenefit } from "../../types/perks";

type InfoIconProps = {
  perkId: number;
  selectedPerks: IPerkBenefit[];
};

interface LinkType {
  title?: string;
  redeemInstructionRichText?: string;
}

const InfoIcon = (props: InfoIconProps) => {
  const {
    perksState: { perks },
    perksDispatch,
  } = useContext(PerksContext);
  const { contentState }: { contentState: ContentStateType } = useContext(ContentContext);

  const { infoIcon, infoIconCategory, infoIconColor } = contentState?.Content[1]?.perkSelection || {};

  const infoIconStyles = cxs({
    color: `var(--${infoIconColor}, #2E2E2E)`,
  });

  const getPerkTermsAndConditions = (perkId: number) => {
    const perk = contentState?.Content[4]?.perks?.find((perk) => perk?.id === perkId);
    if (perk) {
      return perk.termsAndConditions;
    }
  };

  const getLink = () => {
    const link: LinkType | undefined = getPerkTermsAndConditions(props.perkId);
    return link;
  };

  const handleTileInfoClick = (
    data: any,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    resultObject: Record<string, unknown>
  ) => {
    event.preventDefault();
    if (data) data.redeemInstructionCtaHandling = "onClick";
    perksDispatch({
      type: "SET_ACTIVE_SCREEN",
      value: {
        ...{
          screenName: PerksScreens.TermsAndCondition,
          previousScreen: PerksScreens.PerksSelection,
          title: data?.title,
        },
        ...{ data },
        ...{ placeholderNameObj: resultObject },
      },
    });

    perksDispatch({
      type: ACTIONS.SET_SELECTED_PERK_BENEFITS,
      value: props.selectedPerks,
    });
  };

  return (
    <button
      title="More Information"
      onClick={(event) =>
        handleTileInfoClick(
          getLink(),
          event,
          extractKeysAndCreateObject(getLink()?.redeemInstructionRichText as string, perks.pastPerksList[0])
        )
      }
      type="button"
      data-testid={"perk-tile-info-icon-" + props.perkId}
      className={`perksSelectionPage__tiles informationIcon ${infoIconStyles}`}
    >
      <auro-icon
        class="informationIcon__icon"
        category={infoIconCategory ?? "alert"}
        name={infoIcon ?? "question-stroke"}
        customSize
        customColor
        data-testid="icon-element"
      ></auro-icon>
    </button>
  );
};

export default InfoIcon;
