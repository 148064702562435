import "./index.scss";
import "@aurodesignsystem/auro-icon";
import "@aurodesignsystem/auro-header";
import UserAccountContext from "../../context/UserAccountContext";
import { AccountStateType } from "../../state/InitialState";
import mpChoiceIcon from "../../assets/mpchoice-icon.png";
import { useContext } from "react";
import {
  getMemberBalance,
  getMemberFirstName,
  getMileagePlanNumber,
} from "../../helpers/getMemberData";

const MP100KGuestOverview = () => {
  const { accountState }: { accountState: AccountStateType } =
    useContext(UserAccountContext);

  return (
    <section className="MP100KGuestOverview">
      {/* <div className="MP100KGuestOverview_ASLink">
        <auro-hyperlink
          href={`https://www.alaskaair.com/account/overview`} type="nav">
          <auro-icon customColor name="chevron-left" category="interface" />
          Account overview
          </auro-hyperlink>
      </div> */}
      <div className="MP100KGuestOverview_content">
        <div className="mpOverviewWrapper">
          <div className="mpOverviewIcon">
            <img src={mpChoiceIcon} alt="MP Choice Icon"></img>
          </div>
          <div className="mpOverviewBody">
            <div className="mp100kguestHeading">
              <auro-header>
                Congratulations, {getMemberFirstName(accountState)}!
              </auro-header>
            </div>
            <div className="mp100kguestDetails">
              <div className="">
                Available miles: {getMemberBalance(accountState)}
                <br />
                Mileage Plan Number: {getMileagePlanNumber(accountState)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="MP100KGuestOverview_notification">
        <div className="choiceNotification--One">
          Thank you for being an Alaska Airlines MVP<sup>®</sup> Gold 100K
          member. We'd like to invite you to select your 100K Choice Benefit for
          the next program year.<sup>1</sup> Choice Benefit selections must be
          made by January 31, 2025.
        </div>
      </div>
    </section>
  );
};

export default MP100KGuestOverview;
