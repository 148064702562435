import React, { useContext, useEffect } from "react";
import { PerksContext } from "../../context/PerksContext";
import { PerksScreens } from "../../helpers/constants";
import PerksHub from "../MPPerks/MPPerksContainer";
import PerksSelection from "../MPPerksSelection/MPPerksSelection";
import PerksSelectionConfirmation from "../MPPerksSelection/MPPerksSelectionConfirmation";
import PerksSelectionSuccess from "../MPPerksSelection/MPPerksSelectionSuccess";
import PerksSelectionError from "../MPPerksSelection/MPPerksSelectionError";
import TermsAndConditionViewer from "../MPPerks/TermsAndCondition";
import PerksLadder from "../MPPerksLadder";
import "./styles.scss";
import "../../excursion.scss";

const MPPerksPage = () => {
  const { perksState } = useContext(PerksContext);
  const { activeScreen } = perksState;

  const screenComponents = {
    [PerksScreens.Hub]: <PerksHub />,
    [PerksScreens.PerksSelection]: <PerksSelection />,
    [PerksScreens.PerksSelectionConfirmation]: <PerksSelectionConfirmation />,
    [PerksScreens.PerksSelectionSuccess]: <PerksSelectionSuccess />,
    [PerksScreens.PerksSelectionError]: <PerksSelectionError />,
    [PerksScreens.TermsAndCondition]: <TermsAndConditionViewer />,
    [PerksScreens.PerksLadder]: <PerksLadder />,
  };

  useEffect(() => {
    if (activeScreen && activeScreen.screenName in screenComponents) {
      window.scrollTo(0, 0);
    }
  }, [activeScreen]);

  const getActiveScreen = () => {
    if (activeScreen) {
      const as = screenComponents[activeScreen.screenName];
      if (as) {
        return React.cloneElement(as, { ...activeScreen });
      }
      return as;
    }
    return <></>;
  };

  return <div className="page-container">{getActiveScreen()}</div>;
};

export default MPPerksPage;
