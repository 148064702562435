/**
 * Checks if the provided string contains "date" (case insensitive).
 *
 * This function uses a regular expression to test if the substring
 * "date" appears in the input string, ignoring case.
 *
 * @param {string} value - The string to be checked.
 * @returns {boolean} - Returns true if "date" is found in the string,
 *                      false otherwise.
 */
export const isStringContainsDate = (value: string): boolean => {
  return /date/i.test(value);
};
