export interface IconContent {
  perkId?: number;
  name: string;
  category: string;
}

export function getIcon(
  perkBenefitId: number,
  list: { perkId: string; name: string; category: string }[] | undefined,
  defaultCategory: string | undefined,
  defaultIconName: string | undefined
): IconContent {
  let icon: IconContent = {
    category: defaultCategory ?? "",
    name: defaultIconName ?? "",
    perkId: perkBenefitId as number,
  };

  var targetIcon = list?.find((icon) => Number(icon.perkId) === Number(perkBenefitId));

  if (targetIcon) {
    icon.category = targetIcon.category;
    icon.name = targetIcon.name;
  } else {
    icon.category = defaultCategory ?? "";
    icon.name = defaultIconName ?? "";
  }

  return icon;
}
