import { useContext, useState } from "react";
import cxs from "cxs";
import PerksTileList from "../PerkTileList";
import { ACTIONS, PerksContext } from "../../../context/PerksContext";
import { ContentContext } from "../../../context/ContentContext";
import RoundedButton from "../../RoundedButton/RoundedButton";
import Nav from "../../MPPerksNavigation";
import { PerksScreens } from "../../../helpers/constants";
import { IPerkBenefit } from "../../../types/perks";
import "./index.scss";

const MPPerksSelection = () => {
  const { perksState, perksDispatch } = useContext(PerksContext);
  const { selectedMilestonePerk, selectedPerkBenefits, perks } = perksState;
  const { contentState } = useContext(ContentContext);
  const [selectedPerks, setSelectedPerks] = useState<IPerkBenefit[]>(selectedPerkBenefits);
  const [isSelectDisabled, setIsSelectDisabled] = useState<boolean>(selectedPerks.length === 0);
  const milestone = perks.availableMilestonePerks.find(
    (milestone) => milestone.milestoneName === selectedMilestonePerk?.milestoneName
  );

  const {
    backgroundColor,
    titleColor,
    subtitleColor,
    ctaButtonTitle,
    ctaDisabledBackgroundColor,
    ctaDisabledColor,
    ctaEnabledBackgroundColor,
    ctaEnabledColor,
  } = contentState?.Content[1]?.perkSelection || {};

  const {
    backgroundColor: headerBackgroundColor,
    subtitle: headerSubtitle,
    subtitleColor: headerSubtitleColor,
    ctaColor,
    cta,
  } = contentState?.Content[1]?.perkSelection?.header || {};

  const { title: navTitle, multipleTitle } = contentState?.Content[1]?.perkSelection?.top || {};

  const styles = {
    container: cxs({
      background: `var(--${backgroundColor}, #FBFFF6)`,
    }),
    header: cxs({
      backgroundColor: `var(--${headerBackgroundColor}, #defcd6)`,
    }),
    headerSubtitle: cxs({
      color: `var(--${headerSubtitleColor}, #2e2e2e)`,
    }),
    link: cxs({
      color: `var(--${ctaColor}, #2e2e2e)`,
    }),
    title: cxs({
      color: `var(--${titleColor}, #151515)`,
    }),
    subtitle: cxs({ color: `var(--${subtitleColor}, #151515)` }),
    selectBtn: cxs({
      backgroundColor: isSelectDisabled
        ? `var(--${ctaDisabledBackgroundColor}, #d4d4d4)`
        : `var(--${ctaEnabledBackgroundColor}, #03314f)`,
      color: isSelectDisabled ? `var(--${ctaDisabledColor}, #ffffff)` : `var(--${ctaEnabledColor}, #ffffff)`,
    }),
  };

  const transformedHeaderSubtitle = headerSubtitle?.replace("{milestone}", `${selectedMilestonePerk?.milestoneName}`);

  const handleSelectButton = () => {
    perksDispatch({
      type: ACTIONS.SET_SELECTED_PERK_BENEFITS,
      value: selectedPerks,
    });
    perksDispatch({
      type: ACTIONS.SET_ACTIVE_SCREEN,
      value: { screenName: PerksScreens.PerksSelectionConfirmation },
    });
  };

  const handleTileSelect = (id: number, index: number) => {
    const selectedPerk = milestone?.perkBenefitsLst.find((perk) => perk.perkBenefitId === id)!;
    setSelectedPerks((previousSelectedPerks) => {
      let updatedPerks = [...previousSelectedPerks];
      updatedPerks[index] = selectedPerk;
      setIsSelectDisabled(
        updatedPerks.filter((perk) => perk !== undefined).length !== milestone?.numberOfPerksSelectable
      );
      return updatedPerks;
    });
  };

  return (
    <div className={`perksSelectionPage ${styles.container}`} data-testid="perks-selection">
      <Nav
        title={
          (milestone?.numberOfPerksSelectable ?? 0) > 1
            ? multipleTitle ?? "Select your perks"
            : navTitle ?? "Select your perk"
        }
        returnToScreen={PerksScreens.Hub}
      />
      <div className={`perksSelectionPage__header ${styles.header}`} data-testid="perks-selection-header">
        <p className={`${styles.headerSubtitle}`}>{transformedHeaderSubtitle}</p>
        <div className={`${styles.link}`}>{cta?.title}</div>
      </div>
      <div className="perksSelectionPage__selectionContainer">
        {Array.from({ length: milestone?.numberOfPerksSelectable ?? 0 }).map(
          (_, index) =>
            milestone && (
              <PerksTileList
                key={index}
                index={index}
                milestone={milestone}
                selectedPerks={selectedPerks}
                handleTileSelect={handleTileSelect}
                testid="tile-list"
              />
            )
        )}
        <div className="perksSelectionPage__tilesContainer">
          <RoundedButton
            className={`${isSelectDisabled ? "disabled" : ""} ${styles.selectBtn}`}
            label={ctaButtonTitle ?? "Select"}
            disabled={isSelectDisabled}
            onClick={handleSelectButton}
            type="button"
            testId="select-button"
          />
        </div>
      </div>
    </div>
  );
};

export default MPPerksSelection;
