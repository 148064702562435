import { useContext } from "react";
import cxs from "cxs";
import SelectPerksCTA from "../MPSelectPerksCTA";
import { ContentContext } from "../../../../context/ContentContext";
import { PerksContext } from "../../../../context/PerksContext";
import "./index.scss";

const MPSelectPerksContainer = () => {
  const { contentState } = useContext(ContentContext);
  const {
    perksState: { perks },
  } = useContext(PerksContext);

  const { titleColor, title } = contentState.Content[0].selectPerks || {};

  const styles = {
    title: cxs({
      color: `var(--${titleColor}, #151515)`,
    }),
  };

  return (
    <>
      {perks.availableMilestonePerks.length ? (
        <div className="selectPerks" data-testid="select-perk-list">
          <h2 className={`selectPerks__title ${styles.title}`} data-testid="select-perk-list-title">
            {title}
          </h2>
          <div className="selectPerks__ctaWrapper">
            {perks.availableMilestonePerks.map((milestone, index) => (
              <SelectPerksCTA milestone={milestone} key={index} />
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default MPSelectPerksContainer;
