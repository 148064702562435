import "./index.scss";
import UserAccountContext from "../../context/UserAccountContext";
import { AccountStateType } from "../../state/InitialState";
import headerBackGround from "../../assets/jeep_beachside_avis.png";
import { useContext } from "react";
import { getMemberFirstName, getMemberTierName, getABGTierName, getABGTierIcon } from "../../helpers/getMemberData";

const ABGGuestOverview = () => {
  const { accountState }: { accountState: AccountStateType } = useContext(UserAccountContext);

  return (
    <div className="ABGGuestOverview">
      <div className="ABGGuestOverview_backgroundImage">
        <auro-background 
          bg={`url(${headerBackGround}) center center/cover no-repeat`}
          height="120px"
          heightSm="200px"
          heightMd="300px"
        />
      </div>
      <div className="ABGGuestOverview_content">
        <div className="ABGOverviewIcon">
          <img src={getABGTierIcon(accountState)} alt="Avis logo"></img>
        </div>
        <div className="ABGOverviewHeader">
          <auro-header>
            <div className="header-text">Congratulations, {getMemberFirstName(accountState)}!</div>
          </auro-header>
        </div>
        <div className="ABGOverviewBody">
          <auro-header>
            <div className="bodytext">
              As a Mileage Plan {getMemberTierName(accountState)} member, you are eligible for complimentary Avis {getABGTierName(accountState)}<sup>®</sup> status.
            </div>
          </auro-header>
        </div>
      </div>
    </div>
  );
};

export default ABGGuestOverview;
