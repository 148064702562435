import { useContext, useState,  useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { AccountStateType } from "../../state/InitialState";
import UserAccountContext from "../../context/UserAccountContext";
import submitABGData from "../../hooks/ABGStatusMatchService";
import { AvisForgotNameLink, ABGConfirmationPath } from "../../helpers/constants";
import "./index.scss";
import ABGStrings from "../../helpers/tagging/ABG-strings";
import { addTaggingUtil, createUtagObject, waitForUtagAndLink, waitForUtagAndTrack } from "../../helpers/tagging/tagging-utils";

const ABGForm = () => {
    const { accountState }: { accountState: AccountStateType } = useContext(UserAccountContext);
    const [showLoader, setShowLoader] = useState(false);
    const initialInputState = {
        AvisWizardNumber: "",
        Email: "",
        ZipCode: "",
        isTermsChecked: false,
        isFormValid: false
    };
    const initialFormErrorState = {
        isError: false,
        errorMessage: ""
    };
    const [formData, setFormData] = useState(initialInputState);
    const [formError, setFormError] = useState(initialFormErrorState);
    const navigate = useNavigate();

    useMemo(() => {
        let { page_name, channel, events } = ABGStrings.tags.abg_status_match_landing.load;
        waitForUtagAndTrack(createUtagObject(page_name, channel, events));
        addTaggingUtil();
    }, []);

    const handleInputChange = (e: any, key: any) => {
        if (key === 'isTermsChecked') {
            setFormData({ ...formData, [key]: e.target.checked });
        }
        else {
            setFormData({ ...formData, [key]: e.value });
        }
    };

    async function submitABGMatch(e: any) {
        if (formError.isError) { setFormError({ ...formError, isError: false, errorMessage: "" }) };
        if (formData.isFormValid) {
            setShowLoader(true);
            const res = await submitABGData(formData, accountState);

            if (res && (res.status === 200 || res.status === 202)) {
                navigate(ABGConfirmationPath);
            } else {
                setFormError({ ...formError, isError: true, errorMessage: res.data });
                setShowLoader(false);

                let { page_name, channel, events } = ABGStrings.tags.abg_status_match_error.load;
                waitForUtagAndTrack(createUtagObject(page_name, channel, events));
                addTaggingUtil();
            }
        }
    }

    // set form to valide when fields are not empty and terms checked
    if (formData.ZipCode !== "" && formData.AvisWizardNumber !== "" && formData.Email !== "" && formData.isTermsChecked) {
        formData.isFormValid = true;
    }
    else {
        formData.isFormValid = false;
    }

      const termsOnClick = () => {
        waitForUtagAndLink(ABGStrings.tags.abg_status_match_link_terms.link_tracking);
      }

    return (
        <form className="ABGForm">
            {formError.isError ?
                <div className="form-alert-container">
                    <auro-alert type="error">{formError.errorMessage}</auro-alert>
                </div>
                : null
            }
            <div className="form-container">
                <legend >
                    If you are already an Avis Preferred Member, register below. The information must match what is in your Avis profile.
                </legend>
                <div className="ABGForm_ABGInputFields">
                    <auro-input bordered required onInput={(e: any) => { handleInputChange(e.target, "AvisWizardNumber") }} id="AvisWizardNumber" maxlength="6" setCustomValidityBadInput="Only 6 characters allowed - alpha numeric (letters and numbers only)" pattern="[0-9a-zA-Z]{6,6}">
                        <span slot="label">Avis wizard number</span>
                    </auro-input>
                </div>
                <div className="ABGForm_ABGUsernameLink">
                    <auro-hyperlink target="_blank" href={AvisForgotNameLink}>
                        Forgot your Avis wizard number?
                    </auro-hyperlink>
                </div>
                <div className="ABGForm_ABGInputFields">
                    <auro-input bordered required type="email" onInput={(e: any) => { handleInputChange(e.target, "Email") }} id="Email">
                        <slot slot="helptext"></slot>
                        <slot slot="label">Avis profile email</slot>
                    </auro-input>
                </div>
                <div className="ABGForm_ABGInputFields">
                    <auro-input bordered required onInput={(e: any) => { handleInputChange(e.target, "ZipCode") }} id="ZipCode">
                        <slot slot="label">Zip code</slot>
                    </auro-input>
                </div>
                <div className="ABGForm_ABGInputFields" id="terms-conditions-field-container">
                    <auro-checkbox id="termsAndConditions" value={formData.isTermsChecked} name="termsAndConditions"
                        onInput={(e: any) => { handleInputChange(e, "isTermsChecked") }}   >
                        I accept the <a href="https://www.alaskaair.com/content/legal/terms-conditions/contest-rules/avis-status-match" onClick={() => termsOnClick()}  target="_blank" rel="noreferrer">terms and conditions</a>
                    </auro-checkbox>
                </div>
                <div className="ABGForm_button">
                    <auro-button onClick={submitABGMatch} disabled={(!formData.isFormValid ? 'disabled' : null)} loading={(showLoader ? 'loading' : null)}>Register for status</auro-button>
                </div>
            </div>
        </form>
    );
};

export default ABGForm;