import { useRef, useEffect, MutableRefObject } from 'react';

function useProductPageTitle(title: string, prevailOnUnmount = false) {
  const defaultTitle: MutableRefObject<string> = useRef(document.title);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => () => {
    if (!prevailOnUnmount) {
      document.title = defaultTitle.current;
    }
  }, []);
}

export default useProductPageTitle;