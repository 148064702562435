const loginPageURL =
  "https://www.alaskaair.com/www2/ssl/myalaskaair/MyAlaskaAir.aspx?CurrentForm=UCSignInStart&url=https://www.alaskaair.com/account/mpchoice&advise=eSessionTimeout";

//-- Renews the session on AS.com
const renewSession = () =>
  fetch(`/booking/signin/ExtendExpirationTimeout?t=${new Date().getTime()}`);

//-- Sends a POST request to end user session and redirects to AS.com login page
const terminateSession = () => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ t: new Date().getDate() }),
  };

  fetch("/account/handlesignout", requestOptions).then(() => {
    redirectToAScomLoginPage();
  });
};

const redirectToAScomLoginPage = () => {
  window.location.href = loginPageURL;
};

const sessionModule = {
  renewSession,
  terminateSession,
  redirectToAScomLoginPage,
};

export default sessionModule;
