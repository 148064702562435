import ABGBenefits from "../ABGBenefits";
import ABGGuestOverview from "../ABGGuestOverview";
import ABGFooter from "../ABGFooter";
import ABGForm from "../ABGForm";
import "./index.scss";
import useProductPageTitle from "../../hooks/useProductPageTitle";
import { AvisProductPageTitle, BasePageTitle } from "../../helpers/constants";

function ABGContainer() {
  useProductPageTitle(`${AvisProductPageTitle}${BasePageTitle}`);

  return (
    <div className="ABGFormPage">
      <section className="ABGFormPage_header">
        <ABGGuestOverview />
      </section>
      <section className="ABGFormPage_icon">
        <ABGBenefits />
      </section>
      <section className="ABGFormPage_form">
        <ABGForm />
      </section>
      <section className="ABGFormPage_footer">
        <ABGFooter />
      </section>
    </div>
  );
}

export default ABGContainer;