import "@aurodesignsystem/auro-icon";
import "@aurodesignsystem/auro-badge";
import "./index.scss";

export interface MPPerkIconProps {
  id: number;
  type: any;
  category: any;
}

export default function MPPerkIcon(perk: Readonly<MPPerkIconProps>) {
  return (
    <auro-badge class={`perk-badge ${perk.type}`}>
      <auro-icon
        class="perk-icon"
        category={perk.category}
        name={perk.type}
        customSize
        customColor
      />
    </auro-badge>
  );
}