import { useContext, useEffect, useState } from "react";
import cxs from "cxs";
import PerksTile from "../MPPerksTile";
import RoundedButton from "../../RoundedButton/RoundedButton";
import { enrollPerks, getPerkSelectionTokens } from "../../../services/PerksService";
import UserAccountContext from "../../../context/UserAccountContext";
import { ContentContext } from "../../../context/ContentContext";
import { ACTIONS, PerksContext } from "../../../context/PerksContext";
import { PerksScreens } from "../../../helpers/constants";
import { getIcon } from "../../../helpers/getIcon";
import { IEnrollPerksRequest, IPerkBenefit } from "../../../types/perks";
import { AccountStateType } from "../../../state/InitialState";
import { appInsights } from "../../../services/AppInsights";
import "./index.scss";

const MPPerksSelectionConfirmation = () => {
  const { accountState } = useContext(UserAccountContext);
  const { contentState } = useContext(ContentContext);
  const { perksState, perksDispatch } = useContext(PerksContext);
  const [perkSelectionTokens, setPerkSelectionTokens] = useState<string[]>([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { Value: bearerToken } = accountState.Token;

  const { selectedPerkBenefits, selectedMilestonePerk } = perksState;

  const {
    backgroundColor,
    titleColor,
    title,
    subtitle,
    subtitleColor,
    cancelButtonTitle,
    confirmButtonTitle,
    cancelButtonBackgroundColor,
    confirmButtonBackgroundColor,
    confirmButtonDisabledBackgroundColor,
    confirmButtonTextColor,
    confirmButtonDisabledColor,
  } = contentState?.Content[1]?.perkSelection?.confirmSelection || {};

  const { list, default: defaultIconName, defaultCategory } = contentState?.Content[2]?.icons || {};

  const contentPerks: any = contentState?.Content[4]?.perks || {};

  const styles = {
    container: cxs({
      background: `var(--${backgroundColor}, #FBFFF6)`,
    }),
    header: cxs({ color: `var(--${titleColor}, #151515)` }),
    subtitle: cxs({ color: `var(--${subtitleColor}, #2e2e2e)` }),
    confirmButton: cxs({
      backgroundColor: isButtonDisabled
        ? `var(--${confirmButtonDisabledBackgroundColor}, #d4d4d4)`
        : `var(--${confirmButtonBackgroundColor}, #03314f)`,
      color: isButtonDisabled
        ? `var(--${confirmButtonTextColor}, #ffffff)`
        : `var(--${confirmButtonDisabledColor}, #ffffff)`,
    }),
    cancelButton: cxs({
      borderColor: `var(--${cancelButtonBackgroundColor}, #ffffff)`,
    }),
  };

  const DispatchResultScreen = (status: "Error" | "Success") => {
    const screenName = status === "Error" ? PerksScreens.PerksSelectionError : PerksScreens.PerksSelectionSuccess;
    perksDispatch({
      type: ACTIONS.SET_ACTIVE_SCREEN,
      value: { screenName },
    });
  };

  useEffect(() => {
    const fetchPerkSelectionTokens = async () => {
      if (selectedMilestonePerk) {
        try {
          const payload = {
            milestoneId: selectedMilestonePerk.memberSelectionLst[0].milestoneId,
            mpNumber: accountState.MileagePlanDetails.MileagePlanNumber,
            accountGuid: accountState.Token.Guid,
          };
          const res = await getPerkSelectionTokens(payload, bearerToken);

          if (res) {
            setPerkSelectionTokens(res.data.tokens);
            setIsButtonDisabled(false)
          }
        } catch (error) {
          console.error("Error getting perk selection tokens:", error);
        }
      }
    };

    fetchPerkSelectionTokens();
  }, []);

  const createPayload = (
    perk: IPerkBenefit,
    milestoneId: number,
    accountState: AccountStateType
  ): IEnrollPerksRequest => ({
    milestoneId,
    enrollPerkId: perk.perkBenefitId,
    firstName: accountState.MileagePlanDetails.FirstName,
    lastName: accountState.MileagePlanDetails.LastName,
    emailAddress: accountState.MileagePlanDetails.Email,
    mpNumber: accountState.MileagePlanDetails.MileagePlanNumber,
    accountGuid: accountState.Token.Guid,
    nominee: {
      firstName: accountState.MVPGoldNominationDetails.FirstName,
      lastName: accountState.MVPGoldNominationDetails.LastName,
      mpNumber: accountState.MVPGoldNominationDetails.MileagePlanNumber,
      emailAddress: accountState.MVPGoldNominationDetails.Email,
    },
  });

  const handleConfirm = async () => {
    if (perkSelectionTokens.length !== selectedPerkBenefits.length) {
      const errorMessage = "Perk selection tokens and selected perks do not match";
      appInsights.trackException({ exception: new Error(errorMessage) });
      return;
    }
    if (selectedMilestonePerk && selectedPerkBenefits.length > 0) {
      const milestoneId = selectedMilestonePerk.memberSelectionLst[0].milestoneId;
      const perkBenefits: IPerkBenefit[] = [];
      setIsButtonDisabled(true);

      try {
        var perkSelectionTokenIndex = 0;
        for (const perk of selectedPerkBenefits) {
          const payload = createPayload(perk, milestoneId, accountState);
          const res = await enrollPerks(
            payload,
            accountState.Token.Value,
            perkSelectionTokens[perkSelectionTokenIndex++]
          );

          if (res) {
            perkBenefits.push({ ...perk, isSuccessfullyEnrolled: res?.status === 200 });
          }
        }
        perksDispatch({
          type: ACTIONS.SET_SELECTED_PERK_BENEFITS,
          value: perkBenefits,
        });
        const hasFailed = perkBenefits.find((perk) => !perk.isSuccessfullyEnrolled);
        setIsButtonDisabled(false);
        if (hasFailed) {
          DispatchResultScreen("Error");
        } else {
          DispatchResultScreen("Success");
          setPerkSelectionTokens([]);
        }
      } catch (error) {
        console.error("Error enrolling perks:", error);
        DispatchResultScreen("Error");
        setIsButtonDisabled(false);
      }
    }
  };

  const handleCancel = () => {
    perksDispatch({
      type: ACTIONS.SET_ACTIVE_SCREEN,
      value: { screenName: PerksScreens.PerksSelection },
    });
  };

  const getSubtitle = (perksId: number, perkContentLst: any) => {
    var perkItem = perkContentLst?.find((item: any) => item.id === perksId);
    return perkItem?.perkSelectionTileSubtitle;
  };

  return (
    <div className={`perksConfirmationPage__container ${styles.container}`}>
      <div className="perksConfirmationPage__headerText">
        <h2 className={`perksConfirmationPage__title ${styles.header}`}>{title}</h2>
        <p className={`perksConfirmationPage__subtitle ${styles.subtitle}`}>{subtitle}</p>
      </div>
      <div className="perksConfirmationPage__tilesContainer">
        {selectedPerkBenefits.map((perk, index) => {
          const icon = getIcon(perk.perkBenefitId, list, defaultCategory, defaultIconName);

          return (
            <div className="perksConfirmationPage__tile" key={index}>
              <PerksTile
                id={perk.perkBenefitId}
                selected={true}
                description={perk.perkDescription}
                perkSelectionTileSubtitle={getSubtitle(perk.perkBenefitId, contentPerks)}
                icon={icon.name}
                category={icon.category}
                handleClick={() => {}}
                variant="outline"
                isButton={false}
                selectedPerks={selectedPerkBenefits}
              />
            </div>
          );
        })}
      </div>
      <div className="perksConfirmationPage__buttons">
        <RoundedButton
          className={styles.confirmButton}
          label={confirmButtonTitle ?? "Confirm"}
          type="button"
          onClick={handleConfirm}
          disabled={isButtonDisabled}
        />
        <RoundedButton
          className={styles.cancelButton}
          label={cancelButtonTitle ?? "Choose a different perk"}
          type="button"
          outline
          onClick={handleCancel}
          disabled={isButtonDisabled}
        />
      </div>
    </div>
  );
};

export default MPPerksSelectionConfirmation;
