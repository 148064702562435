
import { FormEventHandler } from "react";

const NominationForm = (props: {
 handleSubmit: FormEventHandler<HTMLFormElement> | undefined; handleLink: any; data: any; returnData: any;  
}) => {

  return(
    <form onSubmit={props.handleSubmit}>
      <legend>
        <auro-header level="4" display="500" style={{color: "var(--auro-color-brand-midnight-400)", padding: "1rem"}}>
          Enter the information of the person you are nominating
        </auro-header>
      </legend>
      <div className="form-floating mb-3">
    
        <input required type="text" maxLength={26} value={props?.data?.firstName?.value}  onChange={props.returnData} className="form-control" id="firstName" placeholder="firstName"/>
        <label htmlFor="firstName">First name</label>
      </div>
        <div className="form-floating mb-3">
          <input required type="text" maxLength={26}  value={props?.data?.lastName?.value} onChange={props.returnData} className="form-control" id="lastName" placeholder="lastName"/>
          <label htmlFor="lastName">Last name</label>
        </div>
        <div className="form-floating mb-3">
          <input required type="text" minLength={2} value={props?.data?.mileagePlanNumber?.value} onChange={props.returnData} maxLength={12} className="form-control" id="mileagePlanNumber" placeholder="mileagePlanNumber"/>
          <label htmlFor="mileagePlanNumber">Mileage plan number</label>
        </div>
        
        <div className="form-floating mb-3">
          <input required type="email"  value={props?.data?.email?.value} onChange={props.returnData} className="form-control" id="email" placeholder="email"/>
          <label htmlFor="email">Email</label>
        </div>
        <div className="formButton">
          <span>
            <auro-button secondary fluid onClick={props.handleLink}>Cancel and go back</auro-button>
          </span>
          <span>
            <button className="button" type="submit" value="Submit">Submit my nomination</button>
          </span>
        </div>
    </form>
  );
};

export default NominationForm;