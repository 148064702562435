import { Dispatch } from "react";
import { ACTIONS } from "../context/PerksContext";
import { getPerks } from "../services/PerksService";
import { IGetPerksRequest, IPerksResponse } from "../types/perks";

interface PerksAction {
  type: string;
  value: IPerksResponse;
}

const fetchPerksData = async (
  payload: IGetPerksRequest,
  token: string,
  dispatch: Dispatch<PerksAction>
) => {
  try {
    const res = await getPerks(payload, token);
    if (res !== null) {
      dispatch({ type: ACTIONS.SET_PERKS, value: res.data });
    }
  } catch (error) {
    console.error("Error fetching perks data:", error);
  }
};

export default fetchPerksData;
