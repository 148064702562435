import {
  MVP100KTierName,
  MVPTierName,
  MVPGoldTierName,
  MVPGold75KTierName,
  MPChoiceProductName,
  AvisStatusMatchProductName,
  AccountOverviewPath,
  Perks,
  MemberInvalidTierPromotions,
  ASPageNotFoundUrl,
} from "../../helpers/constants";
import { AccountStateType } from "../../state/InitialState";

const redirect = (path: string) => {
  window.location.href = path;
  return null;
};

const ProtectedRoute = ({
  data,
  product,
  children,
  isEnabled = false,
}: {
  data: AccountStateType;
  isEnabled?: boolean;
  product: string;
  children: JSX.Element;
}): any => {
  const user = data.MileagePlanDetails;

  // Checking access to Avis Status Match Product Routes
  if (product === AvisStatusMatchProductName) {
    if (
      user.MileagePlanNumber &&
      (user.TierName === MVPGoldTierName ||
      user.TierName === MVP100KTierName ||
      user.TierName === MVPGold75KTierName ||
      user.TierName === MVPTierName)
    ) {
      return children;
    } else {
      return redirect(AccountOverviewPath);
    }
  }

  // Checking access to Perks Routes
  if (product === Perks) {
    if (!isEnabled) {
      return redirect(ASPageNotFoundUrl);
    }
    if (user.MileagePlanNumber) {
      return children;
    } else {
      return redirect(AccountOverviewPath);
    }
  }
  // Checking access to 100K Choice Product Routes
  else if (
    product === MPChoiceProductName &&
    data.MileagePlanDetails.IsEligibleFor100KChoiceSelection
  ) {
    return children;
  } else {
    return redirect(AccountOverviewPath);
  }
};
export default ProtectedRoute;
