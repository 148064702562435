import "./index.scss";
import MPPerkList from "../MPPastPerks/MPPerkList";
import SelectPerksModule from "../MPSelectPerks/MPSelectPerksContainer";
import { useContext, useEffect } from "react";
import { ContentStateType } from "../../../state/InitialState";
import { ContentContext } from "../../../context/ContentContext";
import cxs from "cxs";
import MPMilestoneContainer from "../MPMilestoneContainer";
import { ACTIONS, PerksContext } from "../../../context/PerksContext";
import Nav from "../../MPPerksNavigation";

const MPPerksContainer = () => {
  const { contentState }: { contentState: ContentStateType } = useContext(ContentContext);
  const { perksDispatch } = useContext(PerksContext);

  const { title } = contentState?.Content[3]?.perksLanding?.topNav || {};

  const introductionMessage =
    contentState?.Content[3]?.perksLanding?.welcomeMessage ??
    "As part of the new Alaska loyalty program, when you achieve a new milestone you'll be able to come here to select from a choice of perks.";

  const styles = {
    perkPageIntroduction: cxs({
      color: `var(--${contentState?.Content[3]?.perksLanding?.welcomeMessageColor}, var(--${contentState?.Content[3]?.perksLanding?.welcomeMessageColor}, #2e2e2e))`,
    }),
    perkPageTitle: cxs({
      color: `var(--${contentState?.Content[3]?.perksLanding?.titleColor}, var(--${contentState?.Content[3]?.perksLanding?.titleColor}, #2e2e2e))`,
    }),
  };

  useEffect(() => {
    perksDispatch({ type: ACTIONS.SET_SELECTED_PERK_BENEFITS, value: [] });
    perksDispatch({ type: ACTIONS.SET_SELECTED_MILESTONE_PERK, value: null });
  }, []);

  return (
    <div className="overviewPerksContainer">
      <Nav title={title ?? "Perks"} returnToScreen="hub" />
      <div className="container">
        <div className="mPPerksHeading">
          <p className={`perk-page-introduction ${styles.perkPageIntroduction}`}>{introductionMessage}</p>
        </div>
        <div className="mPPerksDetails">
          <MPMilestoneContainer />
          <div className="mPSelectedPerks">
            <SelectPerksModule />
            <MPPerkList />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MPPerksContainer;
