import "./style.scss";

import MarkdownRenderer from "../MarkdownRenderer";

type ContentViewerProps = {
  content: string;
  isPage?: boolean;
};

const ContentViewer = (props: ContentViewerProps) => {
  const { content, isPage = true } = props;
  return (
    <article className={isPage ? "content-container container" : ""}>
      <section className="content-data">
        <MarkdownRenderer markdown={content} />
      </section>
    </article>
  );
};

export default ContentViewer;
