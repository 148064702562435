export const parseMarkdown = ({ markdown }: { markdown: string }) => {
  // Convert headings
  let html = markdown
    .replace(/###### (.+)/g, "<h6>$1</h6>")
    .replace(/##### (.+)/g, "<h5>$1</h5>")
    .replace(/#### (.+)/g, "<h4>$1</h4>")
    .replace(/### (.+)/g, "<h3>$1</h3>")
    .replace(/## (.+)/g, "<h2>$1</h2>")
    .replace(/# (.+)/g, "<h1>$1</h1>");

  // Convert bold, italic, strikethrough, and inline code
  html = html
    .replace(/\*\*(.+?)\*\*/g, "<strong>$1</strong>") // Bold
    .replace(/\*(.+?)\*/g, "<em>$1</em>") // Italics
    .replace(/~~(.+?)~~/g, "<del>$1</del>") // Strikethrough
    .replace(/`(.+?)`/g, "<code>$1</code>"); // Inline code

  // Convert unordered lists
  html = html.replace(/^\s*\*\s*(.+)/gm, "<ul>\n<li>$1</li>\n</ul>");

  // Convert list items properly
  html = html.replace(/<\/ul>\s*<ul>/g, ""); // Remove duplicate <ul>
  html = html.replace(/<\/ul>\s*<li>/g, "<li>"); // Handle list items

  // Convert links
  html = html.replace(/\[(.+?)\]\((.+?)\)/g, '<a href="$2">$1</a>');

  // Convert styled elements in Markdown
  html = html.replace(/^\s*<(\w+)\s+style="([^"]+)">\s*(.*?)\s*<\/\1>/gm, '<$1 style="$2">$3</$1>');

  // Convert paragraphs (using double newlines)
  html = html.replace(/\n\n+/g, "<br/><br/>"); // For paragraphs
  html = html.replace(/\n/g, "<br/>"); // Single line breaks

  return html;
};
