import React, { useContext } from "react";
import "./index.scss";
import { formatNumber } from "../../helpers/formatNumber";
import { AccountStateType, ContentStateType } from "../../state/InitialState";
import { ContentContext } from "../../context/ContentContext";
import { replacePlaceholderName } from "../../helpers/replacePlaceholderName";
import { PerksContext } from "../../context/PerksContext";
import UserAccountContext from "../../context/UserAccountContext";

const MPPerkProgressMilestone = () => {
  const {
    perksState: { perks },
  } = useContext(PerksContext);

  const { contentState }: { contentState: ContentStateType } = useContext(ContentContext);
  const { accountState }: { accountState: AccountStateType } = useContext(UserAccountContext);
  const { nextPerkMilestoneMessage: toNextPerkMilestoneMessage, milesProgressMessage } =
    contentState?.Content[3]?.perksLanding?.milestoneSummary?.currentMilestoneDetails || {};

  const { upcomingMilestones } = perks;
  let currentMilestoneMile = Number(accountState?.MileagePlanDetails?.asoaMiles);
  let nextMilestone = upcomingMilestones.nextMilestoneMile;
  let futureMilestone = upcomingMilestones.futureMilestoneMile;

  if (futureMilestone === 0 || futureMilestone === null) futureMilestone = 250000;

  if (currentMilestoneMile >= nextMilestone && nextMilestone <= futureMilestone) {
    nextMilestone = futureMilestone;
  }

  if (currentMilestoneMile >= futureMilestone) {
    currentMilestoneMile = 250000;
  }

  const currentPercentageStatus = (Number(currentMilestoneMile) / nextMilestone) * 100;

  // Calculate the progress percentage based on the current progress and final step
  let progressPercentage = (currentMilestoneMile / nextMilestone) * 98;

  if (nextMilestone >= currentMilestoneMile && nextMilestone !== futureMilestone) {
    progressPercentage *= nextMilestone / futureMilestone;
  }

  // Define the SVG width in pixels (100% width in parent container)
  const svgWidth = 100; // Adjust this if the parent container has a different width

  // Calculate the positions of the upcoming steps
  const lastStepXPosPx = svgWidth; // 30px away from the end of the line
  const lastStepXPosPercentage = (lastStepXPosPx / svgWidth) * 98;

  // Calculate the center position for the next step
  const nextStepXPosPercentage = currentMilestoneMile > nextMilestone ? 98 : (nextMilestone / futureMilestone) * 98;

  // Function to format labels as "10k", "20k", etc.
  const formatLabel = (value: number | undefined) => {
    return value ? `${value / 1000}k` : "";
  };

  const roundToOneDecimal = (num: number) => {
    // If the number is an integer, return it as is
    if (Number.isInteger(num)) {
      return num;
    }
    // Subtract a tiny amount to avoid rounding up
    return Math.floor((num - 0.05) * 10) / 10;
  };

  const checkStringLength = (str: string) => {
    const length = str.replace(/[^a-zA-Z0-9]/g, "").length; // Remove non-alphanumeric characters
    return length;
  };

  return (
    <div className="visual-milestone-container">
      <div className="perks-percentage-status-value">
        {Math.floor(currentPercentageStatus) || 0}
        <span>%</span>
      </div>
      <div className="perks-percentage-status-text">
        {replacePlaceholderName(toNextPerkMilestoneMessage || "", {
          name: formatLabel(nextMilestone),
        })}
      </div>
      <svg width="100%" height="50">
        {/* Background line */}
        <line
          x1="0"
          y1="25"
          x2="98%"
          y2="25"
          stroke="var(--Blue-blue-background-800, #03314F)"
          strokeWidth="2"
          strokeLinecap="round" // Adds rounded edges to the background line
        />
        {/* Progress line */}
        <line
          x1="5"
          y1="25"
          x2={`${roundToOneDecimal(progressPercentage - 1)}%`}
          y2="25"
          stroke="var(--ds-color-excursion-blue-500, #2CD7E1)"
          strokeWidth="10"
          strokeLinecap="round" // Adds rounded edges to the progress line
        />

        {/* Render the next step and the final step */}

        {[nextMilestone, futureMilestone].map((step, index) => {
          const labelValue = step;
          const label = formatLabel(labelValue);
          const labelLength = checkStringLength(label);
          // Determine the position for each step
          let xPos;
          if (step === nextMilestone && step !== futureMilestone) {
            xPos = nextStepXPosPercentage;
          } else {
            xPos = lastStepXPosPercentage;
          }

          // Skip rendering the line and label for steps that are already passed
          if (
            (step === nextMilestone && xPos <= progressPercentage) ||
            (step === futureMilestone && xPos === progressPercentage)
          )
            return null;

          return (
            <React.Fragment key={index}>
              {/* Step line */}
              <line
                x1={`${xPos}%`}
                y1="20"
                x2={`${xPos}%`}
                y2="30"
                stroke="#03314F"
                strokeWidth="4"
                strokeLinecap="round" // Adds rounded edges to the step lines
              />
              {/* Step label */}
              {xPos >= progressPercentage && (
                <text
                  x={`${xPos - (labelLength === 4 ? 3 : 2)}%`}
                  y="45"
                  fontSize="12"
                  textAnchor="middle"
                  fill="var(--Gray-gray-headline-900, #151515)"
                >
                  {label}
                </text>
              )}
            </React.Fragment>
          );
        })}
      </svg>
      <div className="progress-miles-status-text">
        {replacePlaceholderName(milesProgressMessage ?? "", {
          m: formatNumber(currentMilestoneMile) ?? "0",
          tm: formatNumber(nextMilestone) ?? "0",
        })}
      </div>
    </div>
  );
};

export default MPPerkProgressMilestone;
