const ABGBenefitBlock = ({ category, name, headerText }: { category: string, name: string, headerText: string }) => {
    return (
        <div className="abg-benefit">
            <div className="icon-display">
                <auro-icon
                    category={category}
                    name={name}
                    customSize
                    customColor
                >
                </auro-icon>
            </div>
            <auro-header
                level="6"
                display="300"
            >
                <div className="benefit-text">{headerText}</div>
            </auro-header>
        </div>
    )
}

export default ABGBenefitBlock;