import "@aurodesignsystem/auro-loader";
import './styles.scss';

const Spinner = (): JSX.Element => {
  return (
    <div className="spinner-container">
      <auro-loader orbit lg ></auro-loader>
    </div>
  );
};

export default Spinner;