import { useContext } from "react";
import MPPerkCard from "../MPPerkCard";
import "./index.scss";
import { PerksContext } from "../../../../context/PerksContext";

export interface PastPerksList {
  perkBenefitId: number;
  perkName: string;
  perkDescription: string;
  isEnabled: boolean;
  milestoneRequiredMiles: number | string;
  selectionDate: string;
}

export default function MPPerkList() {
  const {
    perksState: { perks },
  } = useContext(PerksContext);

  return (
    <>
      <div className="perk-heading">Perks you have chosen</div>
      <div className="perks-list">
        {perks.pastPerksList?.map((perk: PastPerksList, indx: number) => (
          <MPPerkCard key={indx} {...perk} indx={indx} />
        ))}
      </div>
    </>
  );
}
