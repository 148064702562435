import cxs from "cxs"
import './index.scss'

export enum MilestoneBadgeType {
  Normal = "Normal",
  Colored = "Colored"
}

const MilestoneBadge: React.FC<{
  title: string;
  type: MilestoneBadgeType;
  backgroundColor: string;
  textColor: string;
  offsetColor: string;
}> = (
  props
) => {
  const styles = cxs({
    color: `var(--${props.textColor}, #2A2A2A)`,
    backgroundColor: `var(--${props.backgroundColor}, #FFFFFF)`,
    letterSpacing: props.type === MilestoneBadgeType.Colored
      ? `var(--text-primary-body-sm-letter-spacing, 0.1px)`
      : `0.1px`,
  })

  return (
    <div className="badge-container">
      { props.type != MilestoneBadgeType.Normal &&
        <span className='offset-container'>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="24"
            viewBox="0 0 16 24"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.9912 0C11.298 1.96124 8 6.59548 8 12C8 17.4045 11.298 22.0388 15.9912 24H12C5.37259 24 0 18.6274 0 12C0 5.37258 5.37259 0 12 0H15.9912Z"
              fill={`var(--${props.offsetColor}, #FFFFFF)`}
            />
          </svg>
        </span>
      }
      <span className={`badge ${styles} ${props.type == MilestoneBadgeType.Normal ? "-bordered" : "-offset-negative-6"}`}
        dangerouslySetInnerHTML={{ __html: props.title }}>
      </span>
    </div>
  )
}

export default MilestoneBadge