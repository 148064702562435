import "./styles.scss";

const MP100KGuestAdditionalInfo = () => {
  return (
    <div className="MP100KGuestAdditionalInfoText">
      <p>
        <sup>1</sup>Benefit selection is valid only for Alaska Airlines MVP®
        Gold 100K members who met the published qualification criteria,
        including both the required elite qualifying miles or total segments,
        and the minimum number of Alaska-operated segments. Members who earned
        status via promotions, status match, or status gifts are not eligible.
        Qualified members may select one option from the list of available
        benefits for that program year. Benefit options are subject to change
        without notice. Benefit selection is final, and your choice cannot be
        changed once selected. Benefits chosen are for the qualified elite
        member only, and cannot be gifted to other individuals, with the
        exception of the nomination to MVP Gold status. All Mileage Plan terms
        and conditions apply.
      </p>
      <p>
        <sup>2</sup>Members selecting 50,000 bonus miles will have the miles
        immediately deposited into their Mileage Plan account upon selection of
        this option. Bonus miles are not elite qualifying miles.
      </p>
      <p>
        <sup>3</sup>If your chosen benefit is Alaska Lounge+ Membership, you
        will receive a membership for the entire time between your selection
        date and the end of the program year. For members qualifying for 100K in
        calendar year 2024 for the 2025 program year, the end of the program
        year is December 31, 2025. No refunds will be given for existing Alaska
        Lounge or Lounge+ members who choose this option for any time period
        that overlaps between paid membership, and the membership chosen as an
        elite benefit option. All Alaska Lounge+ membership terms and conditions
        apply. See our{" "}
        <a
          target="_blank"
          href="https://www.alaskaair.com/content/airport-lounge/location-and-hours"
          rel="noreferrer"
        >
          locations
        </a>{" "}
        page for details. Immediate family defined as partner and children
        (under 21 years of age).
      </p>
      <p>
        <sup>4</sup>Members selecting complimentary Wi-Fi will receive a monthly
        Wi-Fi membership valid for complimentary access on Alaska
        Airlines-marketed flights operated by Alaska, Horizon Air or SkyWest
        Airlines, valid from the date of selection of the benefit through the
        end of the program year. For members qualifying for 100K in calendar
        year 2024, the end of the program year is December 31, 2025. No refunds
        will be given for existing monthly or annual Wi-Fi pass members who
        choose this option for any time period that overlaps between paid
        membership, and the selection of the elite benefit option. Inflight
        Wi-Fi may not be available on all flights and speeds are not guaranteed.
        All Intelsat Inflight LLC. (formerly Gogo Inflight) terms and conditions
        apply.
      </p>
      <p>
        <sup>5</sup>If selecting this benefit option, the individual you
        nominated for MVP status upon MVP Gold 75K qualification will be
        upgraded to MVP Gold. If you didn't nominate anyone to MVP status after
        qualifying for 75K, you can select an individual to nominate to MVP
        Gold. Gifted status will be effective within 2 weeks of selection. For
        members qualifying for 100K in calendar year 2024 for the 2025 program
        year, recipient status is valid through December 31, 2025.
      </p>
      <p>
        <sup>6</sup>Members selecting CLEAR Plus membership will receive a
        complimentary membership for the entire time between your selection date
        and the end of the program year. For members qualifying for 100K in
        calendar year 2024 for the 2025 program year, the end of the program
        year is December 31, 2025. No refunds will be given for existing CLEAR
        Plus members who choose this option for any time period that overlaps
        between the paid membership, and the membership chosen as an elite
        benefit option. CLEAR Terms Apply. Read CLEAR{" "}
        <a
          target="_blank"
          href="https://www.clearme.com/member-terms"
          rel="noreferrer"
        >
          terms and conditions
        </a>
        .
      </p>
    </div>
  );
};

export default MP100KGuestAdditionalInfo;
