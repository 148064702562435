import { useEffect } from "react";
import "./styles.scss";

interface IProps {
  Remaining: number;
  OnContinue: Function;
  OnToggle: Function;
}

const TimeoutDialog = (props: IProps) => {
  const { Remaining, OnContinue, OnToggle } = props;
  useEffect(() => {
    return () => {
      OnContinue();
    };
  }, []);

  return (
    <auro-dialog id="timeoutDialog" open={true} onClose="" className="dialog">
      <span slot="header">Are you still there?</span>
      <div slot="content" className="dialog__content">
        <p>
          Your session will expire in {Remaining} seconds. To keep your session
          active, please tap “Continue” or close this dialog.
        </p>
        <div slot="footer" className="auro_containedButtons mg-25">
          <auro-button primary onClick={() => OnToggle()}>
            Continue
          </auro-button>
        </div>
      </div>
    </auro-dialog>
  );
};

export default TimeoutDialog;
