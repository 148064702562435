export default {
  page_name: "myas : avis status match : home",
  channel: "myas",

  tags: {
    abg_status_match_landing: {
      load: {
        page_name: "myas : avis status match : home",
        channel: "myas",
        events: "event154"
      },
    },
    abg_status_match_confirmation: {
        load: {
          page_name: "myas : avis status match : success",
          channel: "myas",
          events: "event155"
        },
      },
      abg_status_match_error: {
        load: {
          page_name: "myas : avis status match : home",
          channel: "myas",
          events: "event5"
        },
      },
      abg_status_match_link: {
        link_tracking: "avis-status-match:book-car"
      }, 
      abg_status_match_link_terms: {
        link_tracking: "avis-status-match:terms"
      }, 
  },
};
