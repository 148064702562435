/**
 * Extracts keys from a string formatted with curly braces and creates an object
 * using those keys to fetch corresponding values from a provided data object.
 *
 * @param str - A string that contains keys enclosed in curly braces (e.g., "example.com/{key1}/{key2}").
 *              The keys should be formatted as {keyName}.
 *
 * @param data - An object that contains key-value pairs. The function will attempt to
 *               extract values using the keys found in the `str` parameter.
 *               The type T should extend an object with string keys and any type of values.
 *
 * @returns An object containing key-value pairs where keys are derived from the `str` parameter
 *          and values are fetched from the `data` parameter. If a key is not found in `data`,
 *          its value in the returned object will be `undefined`.
 */

export const extractKeysAndCreateObject = <T extends { [key: string]: any }>(
  str: string,
  data: T
): { [key: string]: any } => {
  const matches = str?.match(/\{(.*?)\}/g);
  const resultObject: { [key: string]: any } = {};

  // Map over the matches to extract just the strings without the curly braces
  const result = matches ? matches.map((match) => match.slice(1, -1)) : [];

  result.forEach((key) => {
    // Create object with key-value pairs
    resultObject[key] = data[key];
  });

  return resultObject;
};
