import { Fragment } from "react";
import "./index.scss";


const ABGRegistrationLink = process.env.REACT_APP_AVIS_REGISTRATION_URL as string;

const ABGFooter = () => {

  const pageNavigate = () =>{
    window.open(
      ABGRegistrationLink,
      '_blank'
    );  
  }
  return (
    <Fragment>
      <div className="not-avis-member">
        <auro-header level="6" display="300">
          Not an Avis Preferred Member?
        </auro-header>
        <auro-button secondary onClick={ pageNavigate }>Join now<auro-icon
          category="interface"
          name="external-link"
          customColor
          style={{ color: "var(--auro-color-icon-accent-on-light)" }}
        >
        </auro-icon>
        </auro-button>
      </div>
      <div className="faq-accordion-container">
        <auro-accordion id="faq-accordion">
          <span slot="trigger">Avis status match frequently asked questions</span>
          <p className="faq-heading">Who is eligible to earn complimentary Avis status?</p>
          <ul className="faq-list">
            <li>Mileage Plan MVP Gold 75K and MVP Gold 100K members can enroll to receive Avis President's Club<sup>®</sup> status.</li>
            <li>Mileage Plan MVP and MVP gold members can enroll to receive Avis Preferred Plus<sup>®</sup> status</li>
          </ul>
          <p className="faq-heading">Do I need to be an Avis Preferred member to receive complimentary status?</p>
          <p className="faq-body">Yes, if you are not enrolled in Avis Preferred, you can join <auro-hyperlink target="_blank" href={ABGRegistrationLink}> Avis Preferred for free.</auro-hyperlink> You will receive an email with your Avis wizard number that you can use to complete enrollment for the status.</p>
          <p className="faq-heading">What are the benefits of Avis Preferred Plus status?</p>
          <p className="faq-body">These include:</p>
          <ul>
            <li>Complimentary one-car class upgrade: Alaska Mileage Plan members who are enrolled in Avis Preferred Plus will receive a complimentary one-car class upgrade based on availability. Customers must rent an intermediate (group C) vehicle or larger in order to qualify for an upgrade. Offered at participating Avis locations in the U.S., Canada, Europe, Australia and New Zealand.</li>
            <li>Enjoy expedited service: Go straight to your car at most locations.</li>
          </ul>
          <p className="faq-heading">What are the benefits of Avis President's Club status?</p>
          <ul>
            <li>Guaranteed rental car availability: Alaska Mileage Plan members who are enrolled in Avis President's Club will receive a vehicle as long as they have made a reservation 24 hours in advance of rental checkout, up to a full-size 4-door vehicle.</li>
            <li>Complimentary two-car class upgrades: Alaska Mileage Plan members who are enrolled in Avis President's Club will receive a complimentary two-car class upgrade based on availability. Customers must rent an intermediate (group C) vehicle or larger in order to qualify for an upgrade. Offered at participating Avis locations in the U.S., Canada, Europe, Australia, and New Zealand.</li>
            <li>Enjoy expedited service: Go straight to your car at most locations.</li>
            <li>Dedicated 24/7 phone number for customer support.</li>
          </ul>
          <p className="faq-heading">What happens if my Avis profile is not found when I am completing the status enrollment?</p>
          <p className="faq-body">The email address and zip code that you submitted on the status enrollment page must match the information that is in your Avis profile. The first and last name in your Alaska Mileage Plan account must also match what is listed in your Avis profile. Please check your Avis profile information on <auro-hyperlink target="_blank" href="https://www.avis.com/en/home">Avis.com         
          </auro-hyperlink>
            to ensure that your enrollment information matches. Please re-submit your information on the status page with the correct information.</p>
          <p className="faq-heading">How do I contact Avis for questions about my profile?</p>
          <p className="faq-body">North America (U.S., Canada, and Mexico)</p>
          <p className="faq-body">Preferred: 800-633-3469</p>
          <p className="faq-body">President's Club: 1-800-651-2847</p>
          <p className="faq-body">For more contact phone numbers, please see <auro-hyperlink target="_blank" href="https://www.avis.com/en/customer-service/worldwide-telephone-numbers">Avis International Phone Numbers.</auro-hyperlink></p>
        </auro-accordion>
      </div>
    </Fragment>
  );
};

export default ABGFooter;