import "./styles.scss";
import "@aurodesignsystem/auro-input";

import { useState, useContext, useEffect } from "react";
import UserAccountContext from "../../context/UserAccountContext";
import { AccountStateType } from "../../state/InitialState";
import NominationForm from "../NominationForm";
import Spinner from "../../components/Spinner";
import submitMVP from "../../hooks/MVPNominationService";
import {
  BasePageTitle,
  MpChoiceProductPageTitle,
  ThankYouPagePath,
} from "../../helpers/constants";
import { useNavigate } from "react-router-dom";
import {
  waitForUtagAndTrack,
  createUtagObject,
  addTaggingUtil,
} from "../../helpers/tagging/tagging-utils";
import strings from "../../helpers/tagging/strings";
import useProductPageTitle from "../../hooks/useProductPageTitle";
import ChoiceCardContent from "../../helpers/ChoiceCardContent";

const MVPGoldNominationPage = () => {
  const { accountState }: { accountState: AccountStateType } =
    useContext(UserAccountContext);

  let [formData, setShowData] = useState("");

  const navigate = useNavigate();

  useProductPageTitle(`${MpChoiceProductPageTitle}${BasePageTitle}`);

  useEffect(() => {
    if (showErrors) {
      let { page_name, channel, events } = strings.tags.mpchoice_error.load;
      waitForUtagAndTrack(createUtagObject(page_name, channel, events));
    } else {
      let { page_name, channel, events } =
        strings.tags.mpchoice_mvp_nomination_form.load;
      waitForUtagAndTrack(createUtagObject(page_name, channel, events));
    }
    addTaggingUtil();
  }, []);

  async function handleSubmit(e: any) {
    e.preventDefault();
    const { ...nominationFormData } = e.target.elements;
    formData = nominationFormData;
    accountState.SelectedChoiceMade = ChoiceCardContent[3].id.toString();
    accountState.NominatedName =
      nominationFormData.firstName.value +
      " " +
      nominationFormData.lastName.value;
    onClick();
    const res = await submitMVP(nominationFormData, accountState);

    //response 0 means error here.
    if (res === 0) {
      formSuccess = false;
      formError = true;
      onClick();
      onClickError();
    } else {
      navigate(ThankYouPagePath);
    }
  }

  async function handleData(e: any) {
    formData = e;
    onClickError();
  }

  function handleLink(): void {
    window.location.href = "/account/mpchoice";
  }

  let formSuccess = true;
  let formError = false;
  const [showResults, setShowResults] = useState(false);
  const [showErrors, setShowError] = useState(false);
  const onClick = () => setShowResults(formSuccess);
  const onClickError = () => {
    setShowError(formError);
    setShowData(formData);
  };

  return (
    <div className="NominationFormPage">
      <div className="NominationFormPage_header">
        <div className="headerContent">
          {/* <auro-hyperlink href={`https://www.alaskaair.com/account/overview`} type="nav">
              <auro-icon customColor name="chevron-left" category="interface" />
              Account overview
              </auro-hyperlink>
            <br/> */}
          <div className="nominationFormIcon">
            <auro-icon
              category="in-flight"
              name="elite"
              customSize
              customcolor
              style={{
                width: "5rem",
                color: "var(--auro-color-border-active-on-light)",
                padding: "1rem",
              }}
            ></auro-icon>
          </div>
          <div className="formOverviewHeading">
            <auro-header
              style={{
                color: "var(--auro-color-brand-midnight-400)",
                padding: "1rem",
              }}
            >
              Nominate a friend to MVP Gold status
            </auro-header>
          </div>
          {/* <div className="formOverviewDetails">
              Available miles:  {getMemberBalance(accountState)}<br/>
              Mileage Plan Number: {getMileagePlanNumber(accountState)}
            </div>      */}
        </div>
      </div>
      <div className="NominationFormPage_form">
        <div className="formDescription">
          Choose someone who is an Alaska Airlines Mileage Plan member and
          nominate them to have MVP Gold through Dec 31, 2025!
          <br />
          <br />
          If you nominated someone to MVP status (when you qualified for 75K
          status), you can boost them to MVP Gold through Dec 31, 2025.
          <br />
          <br />
          This choice is final and cannot be changed once submitted. MVP Gold
          status may take up to two weeks to be activated.
        </div>
        {showErrors ? (
          <>
            <br></br>
            <auro-alert id="auroError" type="error">
              There was an error with the form submission. Please try again
              later.
            </auro-alert>
            <br></br>
          </>
        ) : null}
        {!showResults ? (
          <NominationForm
            handleSubmit={handleSubmit}
            handleLink={handleLink}
            data={formData}
            returnData={handleData}
          />
        ) : null}
        {showResults ? <Spinner /> : null}
      </div>
    </div>
  );
};

export default MVPGoldNominationPage;
