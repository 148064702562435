import { Fragment, useContext } from "react";
import UserAccountContext from "../../context/UserAccountContext";
import { AccountStateType } from "../../state/InitialState";
import { ABGPreferredPlusTierName, ABGPresidentsClubTierName } from "../../helpers/constants";
import { getABGTierName } from "../../helpers/getMemberData";
import "./index.scss";
import ABGBenefitBlock from "../ABGBenefitBlock";

const ABGBenefits = () => {
  const { accountState }: { accountState: AccountStateType } = useContext(UserAccountContext);
  const tier = getABGTierName(accountState);

  const ABGPreferredPlusBenefits = [
    { "category": "in-flight", "name": "elite", "headerText": "Complimentary one-car class upgrades—based upon availability at participating locations" },
    { "category": "destination", "name": "car-rental", "headerText": "Expedited rental service—go straight to your car at most locations" }
  ]

  const ABGPresidentsClubBenefits = [
    { "category": "in-flight", "name": "elite", "headerText": "Complimentary two-car class upgrades—based upon availability at participating locations" },
    { "category": "in-flight", "name": "flight-on-time", "headerText": "Guaranteed rental car availability—with a 24-hour advance reservation" },
    { "category": "destination", "name": "car-rental", "headerText": "Expedited rental service—go straight to your car at most locations" },
    { "category": "interface", "name": "phone", "headerText": "Dedicated 24/7 customer support phone number" }
  ]

  let statusMatchBenefits;
  if (tier === ABGPreferredPlusTierName) {
    statusMatchBenefits = (
      <Fragment>
        {
          ABGPreferredPlusBenefits.map((benefit, index) => {
            return <ABGBenefitBlock key={index} category={benefit.category} name={benefit.name} headerText={benefit.headerText} />
          })
        }
      </Fragment>
    )
  } else if (tier === ABGPresidentsClubTierName) {
    statusMatchBenefits = (
      <Fragment>
        {
          ABGPresidentsClubBenefits.map((benefit, index) => {
            return <ABGBenefitBlock key={index} category={benefit.category} name={benefit.name} headerText={benefit.headerText} />
          })
        }
      </Fragment>
    )
  }

  return (
    <div className="container">
      <div className="top-edge">
        <auro-badge pill>Benefits include</auro-badge>
      </div>
      {statusMatchBenefits}
    </div>
  )
}

export default ABGBenefits;