import axios from "axios";
import { appInsights } from "../services/AppInsights";
import { AccountStateType } from "../state/InitialState";

const ABGStatusMatchService = async (formData: any, accountState: AccountStateType) => {
    let ABGStatusMatchURL = process.env.REACT_APP_MPABGSTATUSMATCH_ENDPOINT;

    try {
        appInsights?.trackEvent({ name: "ABG Status Match called, Mileage Plan Number: " + accountState.MileagePlanDetails.MileagePlanNumber });
        if (accountState?.Token?.Guid && accountState?.Token?.Value && formData?.AvisWizardNumber) {
            let res = await axios.post(ABGStatusMatchURL as string,
                {
                    email: formData.Email,
                    postal_code: formData.ZipCode,
                    avis_wizard_number: formData.AvisWizardNumber,
                    first_name: accountState.MileagePlanDetails.FirstName,
                    last_name: accountState.MileagePlanDetails.LastName,
                    account_guid: accountState.Token.Guid,
                    mp_number: accountState.MileagePlanDetails.MileagePlanNumber
                },
                {
                    headers: {
                        Authorization: `Bearer ${accountState.Token.Value}`,
                        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_MPCHOICE_BFF_KEY as string
                    }
                }
            )
            return res;
        }
    } catch (ex: any) {
        appInsights?.trackException({ exception: ex });
        return ex.response;
    }

    return (
        {
            ABGStatusMatchService
        }
    )
}

export default ABGStatusMatchService