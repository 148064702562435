import axios from "axios";
import { MemberBenefitChoice, ASLoginUrl, MP100KPath } from "../helpers/constants"
import jwtDecode from "jwt-decode";
import { appInsights } from "../services/AppInsights";
import PerksEnrollmentTransaction from "./MPPerksService";
import { MPMember } from "./MPPerksService";

let MPEnrollBenefitsURL = process.env.REACT_APP_MPENROLLBENEFITS_ENDPOINT;
let MVPNominationService = async (form:any, accountState: any) => {

    const redirect = (path: string) => {
      window.location.href = path;
    };

    const decodeTokenAndFetchData = (token: string): any => {
      return jwtDecode(token);
    };

    const decodedToken: any = decodeTokenAndFetchData(accountState.Token.Value);
    const nowPlus10Seconds: any = Date.now() / 1000 + 10; // +10 for network timeout
   // Redirect if token has expired.
    if (decodedToken.exp < nowPlus10Seconds) {
      redirect(ASLoginUrl + MP100KPath + "&advise=eSessionTimeout");
    }

    const MVPGoldNominationDetails: MPMember = {
      FirstName: form.firstName.value, 
      LastName: form.lastName.value,
      MPNumber: form.mileagePlanNumber.value,
      EmailAddress: form.email.value
    };

    try {
      appInsights?.trackEvent({ name: "MVPNominationService called, Mileage Plan Number: " + accountState.MileagePlanDetails.MileagePlanNumber });
      if (
        accountState.Token &&
        accountState.Token.Guid &&
        accountState.Token.Value && 
        MVPGoldNominationDetails
      ) {
          const response = await PerksEnrollmentTransaction(
            accountState,
            MemberBenefitChoice.Nomination,
            MVPGoldNominationDetails
          );
         return 1;
      }
    } catch (ex: any) {
      appInsights?.trackException({ exception: ex});
      return 0;
    }

  return {
    MVPNominationService
  };
};

export default MVPNominationService;